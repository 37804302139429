import { useEffect, useState } from 'react'

import axios from 'axios'

import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { useGoogleLogin } from '@react-oauth/google'
import * as Sentry from '@sentry/nextjs'

import Loader from '../../components/loader'
import Image from 'next/image'

import WelcomeLayout from '../../components/redesign/WelcomeLayout'

import TCModal from '../../modules/sign-up/TCModal'
import GoogleTCModal from '../../modules/sign-up/GoogleTCModal'

import { useUser } from '../../contexts/userContext'
import setUser from '../../actions/setUser'
import Input from '@howdy/core/elements/Input'
import PasswordInput from '../../components/redesign/PasswordInput'
import Checkbox from '@howdy/core/elements/Checkbox'
import Button from '@howdy/core/elements/Button'
import LinkButton from '../../components/redesign/Link'
import EnvelopeSimple from '@howdy/core/elements/Icons/EnvelopeSimple'
import useMobile from '../../hooks/useMobile'

export default function SignUp() {
  const {
    userState: { data },
    dispatch,
  } = useUser()
  const router = useRouter()
  const { signupMethod } = router.query
  const { email: queryEmail } = router.query
  const [showTC, setShowTC] = useState(false)
  const [showTCGoogle, setShowTCGoogle] = useState(false)
  const [TCAccepted, setTCAccepted] = useState(false)
  const [signUpFlow, setSignUpFlow] = useState('email')
  const [showEmailSignUp, setShowEmailSignUp] = useState(false)
  const { isMobile } = useMobile()

  const [isLoading, setIsLoading] = useState(false)

  const signUpForm = useForm({
    defaultValues: {
      email: queryEmail || '',
    },
  })

  useEffect(() => {
    if (data?.id && data?.role !== 'LimitedAccess') {
      router.replace('/dashboard')
    }
  }, [])

  useEffect(() => {
    if (signupMethod == 'google') {
      setShowTCGoogle(true)
      setSignUpFlow('google')
    } else if (signupMethod == 'email') {
      setShowEmailSignUp(true)
      setSignUpFlow('email')
    }
  }, [signupMethod])

  useEffect(() => {
    if (queryEmail) {
      signUpForm.setValue('email', queryEmail)
    }
  }, [queryEmail])

  const onSubmitSignUp = ({ email, password }) => {
    setIsLoading(true)

    axios
      .post('/partners/register', {
        password,
        email,
        url: window.location.href,
      })
      .then((response) => {
        dispatch(setUser({ email }))

        if (response?.data?.role === 'LimitedAccess') {
          router.replace('/my-teams/my-candidates')
        } else {
          router.query.redirect
            ? router.replace(router.query.redirect)
            : router.replace('/dashboard')
        }
      })
      .catch((err) => {
        console.log(err.response)
        Sentry.captureException(err)
        toast.error(err?.response?.data?.message || 'Error registering account', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        setIsLoading(false)
      })
  }

  const onGoogleSuccess = useGoogleLogin({
    onSuccess: (response) => {
      const { access_token } = response
      axios
        .post('/partners/register', {
          googleId: access_token,
          url: window.location.href,
        })
        .then((registerResponse) => {
          dispatch(setUser({ email: registerResponse?.data?.email }))
          router.query.redirect
            ? router.replace(router.query.redirect)
            : router.replace('/dashboard')

          setIsLoading(false)
        })
        .catch((err) => {
          Sentry.captureException(err)
          toast.error(err?.response?.data?.message || 'Error login with google', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          })
          setIsLoading(false)
        })
    },
  })

  const onAcceptTC = () => {
    setShowTC(false)
    if (signUpFlow == 'email') {
      setTCAccepted(true)
    } else {
      onGoogleSuccess()
    }
  }

  const onAcceptTCGoogle = () => {
    setShowTCGoogle(false)
    onGoogleSuccess()
  }

  const onOpenTCfromGoogle = () => {
    setShowTCGoogle(false)
    setSignUpFlow('google')
    setShowTC(true)
  }

  if (isLoading) {
    return <Loader loading={isLoading} />
  }

  const getQuery = () => {
    const query = router.query
    const queryMap = Object.keys(query).map((key) => `${key}=${encodeURIComponent(query[key])}`)
    return queryMap.length > 0 ? `?${queryMap.join('&')}` : ''
  }

  return (
    <WelcomeLayout pageTitle='Howdy | Sign up' title='Howdy!'>
      <div className='mt-9 flex w-full flex-col items-center pb-4 font-worksans lg:items-start'>
        <div className='mb-4 flex w-full flex-col gap-5'>
          <Button
            fullWidth={isMobile}
            onClick={() => {
              setShowTCGoogle(true)
            }}
          >
            <div className='flex items-center gap-3'>
              <Image height={20} width={20} src='/google-icon.svg' />
              Create account with Google
            </div>{' '}
          </Button>
          {!showEmailSignUp && (
            <Button
              fullWidth={isMobile}
              style='secondary'
              leftIcon={'/EnvelopeSimple.svg'}
              onClick={() => {
                setShowEmailSignUp(true)
              }}
            >
              <div className='flex items-center gap-3'>
                <div className='group text-howdy-primary-green group-hover:text-white '>
                  <EnvelopeSimple width={20} height={20} color='currentColor' />
                </div>
                Create account with an email
              </div>
            </Button>
          )}
        </div>
        <form
          className={`flex w-full flex-col ${showEmailSignUp ? 'block' : 'hidden'}`}
          onSubmit={signUpForm.handleSubmit(onSubmitSignUp)}
        >
          <Input
            label='Email'
            type='email'
            placeholder='Enter email'
            name='email'
            defaultValue={queryEmail || ''}
            input={{
              ...signUpForm.register('email', {
                required: 'Required',
              }),
            }}
            error={signUpForm.errors && signUpForm.errors.email?.message}
          />
          <PasswordInput
            label='Password'
            type='password'
            placeholder='Enter password'
            name='password'
            error={signUpForm.errors && signUpForm.errors.password?.message}
            register={signUpForm.register('password', {
              required: 'Required',
              maxLength: {
                value: 80,
                message: 'Maximum length is 80',
              },
            })}
          />
          <div className='mb-4 mt-4 flex items-center justify-center gap-y-4 text-base lg:mt-6 lg:justify-start'>
            <Checkbox onChange={() => setTCAccepted(!TCAccepted)} value={TCAccepted} />
            <div className='mt-px flex items-baseline'>
              <p className='ml-2 mr-1.5 font-worksans font-normal'>Accept</p>
              <Button
                style={'tertiary'}
                onClick={() => {
                  setSignUpFlow('email'), setShowTC(true)
                }}
              >
                Terms and conditions
              </Button>
            </div>
          </div>
          <div className='flex w-full justify-center lg:justify-start'>
            <div>
              <Button type='submit' disabled={!TCAccepted}>
                Sign up
              </Button>
            </div>
          </div>
        </form>
        <div className='mt-4 flex justify-center xl:justify-start'>
          <LinkButton href={`/login${getQuery()}`} text={'LOGIN'} preText='Already a user? ' />
        </div>
        <div className='mt-4 flex justify-center text-base xl:justify-start'>
          <LinkButton
            href={'https://www.howdy.com/book-a-demo'}
            text={'Book a demo'}
            postText=' without creating an account'
          />
        </div>
      </div>
      <TCModal display={showTC} acceptAction={onAcceptTC} closeAction={() => setShowTC(false)} />
      <GoogleTCModal
        display={showTCGoogle}
        acceptAction={onAcceptTCGoogle}
        closeAction={() => setShowTCGoogle(false)}
        openTCAction={onOpenTCfromGoogle}
      />
    </WelcomeLayout>
  )
}
